import * as React from 'react';
import { isTaskNotesEditPage, isTaskNotesPage } from '../../Browser';
import { appModel } from '../../models/App';
import TaskInfo from './TaskInfo';
import TaskNotes from '../notes/TaskNotes';
import AppToolbar from '../app/appBar/AppToolbar';
import TaskTabs from './TaskTabs';
import NoteEditor from '../notes/NoteEditor';
import Button from '../../uicomponents/Button';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { LangKey } from '../../LangKey';
import { observer } from 'mobx-react-lite';

const tabs = new TaskTabs();

const useStyles = makeStyles((theme: Theme) => createStyles({
  spacer: {
    flexGrow: 1,
  },
  infoButton: {
    marginRight: theme.spacing(2),
    opacity: 0.9,
  },
}));

const Task = observer(() => {
  const classes = useStyles();
  const pg = appModel.browser.page;
  let content = null;
  let noteEditor = null;

  if (pg.p === 'task') {
    if (pg.p2 === 'info') {
      content = <TaskInfo />;
    } else if (isTaskNotesPage(pg)) {
      if (pg.p3 === 'view') {
        content = <TaskNotes />;
      } else if (pg.p3 === 'new') {
        noteEditor = <NoteEditor state={'new'}/>;
      } else if (isTaskNotesEditPage(pg)) {
        noteEditor = <NoteEditor state={'edit'} noteId={pg.p4}/>;
      }
    }
  }
  const task = appModel.currentTask;
  if (task && task.version) {}
  return (
    <>
      {noteEditor ||
        <>
          <AppToolbar
            title={task ? task.name : ''}
            button={{ type: 'back', page: { p: 'tasks' } }}
            tabs={tabs}
          >
            {task &&
              <>
                <div className={classes.spacer}/>
                <Button
                  className={classes.infoButton}
                  color={'inherit'}
                  variant={'outlined'}
                  onClick={() => appModel.stands.showStand(task.stand)}
                >
                  {LangKey.StandStandInfo}
                </Button>
              </>
            }
          </AppToolbar>
          {content}
        </>
      }
    </>

  );
});

export default Task;
