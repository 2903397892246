import * as React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { AppBarContent } from './AppBar';
import Hidden from '@material-ui/core/Hidden';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Text from '../../../uicomponents/Text';
import IconButton from '../../../uicomponents/IconButton';
import { Page } from '../../../Browser';
import { AppDrawerMenuButton } from '../AppDrawer';
import { observer } from 'mobx-react';
import { Theme } from '@material-ui/core/styles/createTheme';
import { WithStyles, WithTheme, withStyles, withTheme, createStyles } from '@material-ui/styles';
import { appModel } from '../../../models/App';
import Warning from '@material-ui/icons/Warning';
import Tooltip from '@material-ui/core/Tooltip';
import { LangKey } from '../../../LangKey';

const styles = (theme: Theme) => createStyles({
  root: {
    paddingLeft: theme.spacing(3),
    minHeight: theme.appBar.toolBarHeight,
    paddingRight: theme.spacing(1),
  },
  rootWithMenu: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  rootWithBack: {
    paddingLeft: theme.spacing(1),
  },
  button: {
    marginRight: theme.spacing(0.5),
  },
  tabs: {
    width: '100%',
  },
  tab: {
    flexShrink: 0,
    flexGrow: 1,
    maxWidth: 'none',
  },
  title: {
    lineHeight: 1.2,
  },
});

export interface AppToolbarTabs {
  data: { label: string, value: string | number }[];
  value: string | number;
  onClick: (value: string | number) => void;
}

type AppToolbarProps = {
  title?: string | string[];
  button?: { type: 'menu' } | { type: 'back', onClick?: () => void, page?: Page };
  tabs?: AppToolbarTabs;
};

type AppToolbarPropsWithStyles = AppToolbarProps & WithStyles<typeof styles> & WithTheme<Theme>;

@observer
class AppToolbarBase extends React.Component<AppToolbarPropsWithStyles> {
  private _onTabClick = (value: string|number) => () => {
    if (!this.props.tabs) return;
    this.props.tabs.onClick(value);
  }

  componentDidMount() {
    const { theme, tabs } = this.props;
    appModel.toolBarHeight = theme.appBar.toolBarHeight + (tabs ? theme.appBar.tabsHeight : 0);
  }
  componentDidUpdate() {
    this.componentDidMount();
  }

  componentWillUnmount() {
    appModel.toolBarHeight = undefined;
  }

  render() {
    const { classes, button, title } = this.props;
    let rootClasses = '';
    if (button && button.type === 'menu') {
      rootClasses += ' ' + classes.rootWithMenu;
    } else if (button && button.type === 'back') {
      rootClasses += ' ' + classes.rootWithBack;
    }

    return (
      <AppBarContent>
        <Toolbar disableGutters className={rootClasses}>
          {button &&
          <>
            {button.type === 'menu' &&
            <Hidden lgUp>
              <AppDrawerMenuButton className={classes.button}/>
            </Hidden>
            }
            {button.type === 'back' &&
            <IconButton color={'inherit'} className={classes.button} onClick={button.onClick} page={button.page}>
              <ArrowBack/>
            </IconButton>
            }
          </>
          }
          <Text variant={'h6'} color={'inherit'} className={classes.title}>{title}</Text>
          {this.props.children}
          {(appModel.unsavedData || appModel.freeGeometries.getUnsavedFGs().length > 0) && (
            <Tooltip title={LangKey.CommonUnknownSynchronizing.t()} arrow placement={'right'}>
              <Warning color={'error'}/>
            </Tooltip>
          )}
        </Toolbar>
        {this.props.tabs &&
        <Tabs
          variant={'scrollable'}
          className={classes.tabs}
          scrollButtons={'off'}
          value={this.props.tabs.value}
        >
          {this.props.tabs.data.map(tab =>
            <Tab
              className={classes.tab}
              onClick={this._onTabClick(tab.value)}
              key={tab.value}
              value={tab.value}
              label={tab.label.t()}
            />)}
        </Tabs>
        }
      </AppBarContent>
    );
  }
}

export default withStyles(styles)(withTheme<Theme, typeof AppToolbarBase>(AppToolbarBase));
