import * as React from 'react';
import { MapButton } from '@simosol/stands-map';

import { appModel } from '../../../models/App';

import Person from '@material-ui/icons/Person';
import Group from '@material-ui/icons/Group';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { LangKey } from '../../../LangKey';

type Props = {
  className: string,
}

const FGShowMapButton = (props: Props) => {
  const { className } = props;

  const tooltipTitle = appModel.onlyAssigned
    ? LangKey.FreeGeometryTooltipGroup.t()
    : LangKey.FreeGeometryTooltipPerson.t();

  const toggleAssignedToMe = () => {
    appModel.onlyAssigned = !appModel.onlyAssigned
  };

  return (
    <MapButton className={className}>
      <Tooltip title={tooltipTitle} arrow placement={'right'}>
        <IconButton onClick={toggleAssignedToMe}>
          {appModel.onlyAssigned ? <Person /> : <Group />}
        </IconButton>
      </Tooltip>
    </MapButton>
  )
}

export default FGShowMapButton;
