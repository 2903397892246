import * as React from 'react';
import Paper from '@material-ui/core/Paper/Paper';
import Button from '../../uicomponents/Button';
import Text from '../../uicomponents/Text';
import { appModel } from '../../models/App';
import CenterContainer from '../../uicomponents/CenterContainer';
import { LangKey } from '../../LangKey';
import FormTextField from '../../uicomponents/forms/FormTextField';
import { useForm, useValue } from '@simosol/forms/lib/forms';
import { required } from '../../uicomponents/forms/FormRules';
import LanguageSelect from './LanguageSelect';
import { HBox } from '../../uicomponents/Boxes';
import { createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  testLoginButton: {
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(.5),
  },
}));

type Creditails = { client_id: string, username: string, password: string };

const Auth = () => {
  const styles = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [formData] = React.useState(() => ({ client_id: '', username: '', password: '' }));
  const form = useForm(
    formData,
    {
      username: required(),
      password: required(),
    });
  const [error, setError] = React.useState<string>();
  const userName = useValue(form, 'username');
  const password = useValue(form, 'password');
  const [invalidData, setInvalidData] = React.useState<{ username: string, password: string }>();

  React.useEffect(
    () => {
      if (invalidData) {
        if (invalidData.username !== userName || invalidData.password !== password) {
          setInvalidData(undefined);
          setError(undefined);
        }
      }
    },
    [userName, password],
  );

  const onLoginClick = async () => {
    setError(undefined);
    if (form.validateAll()) {
      setLoading(true);
      const formDataSent = { ...formData };
      const res = await appModel.api.sessionStart(formDataSent);
      if (res.error) {
        setLoading(false);
        const errCode = res.error.code;
        if (errCode === 'IptimException.authClientBad') {
          form.setError('client_id', res.error.message);
        } else if (errCode === 'IptimException.authLoginOrPasswordBad') {
          setError(res.error.message);
          setInvalidData(formDataSent);
        } else {
          setError(res.error.message ?? 'Your input contains invalid data.');
        }
      }
    }
  };

  const TestButton = (props: { name: string, credentials: Creditails }) => {
    return (
      <Button
        disabled={loading}
        variant={'contained'}
        color={'secondary'}
        className={styles.testLoginButton}
        onClick={() => testLogin(props.credentials)}
      >
        {props.name}
      </Button>
    );
  };

  const testLogin = async (credentials: Creditails) => {
    setLoading(true);
    const res = await appModel.api.sessionStart(credentials);
    setLoading(false);
    if (res.error) {
      const errCode = res.error.code;
      if (errCode === 'IptimException.authClientBad') {
        form.setError('client_id', res.error.message);
      } else if (errCode === 'IptimException.authLoginOrPasswordBad') {
        setError(res.error.message);
        setInvalidData(credentials);
      } else {
        setError(res.error.message);
      }
    }
  };

  return (
    <CenterContainer>
      <Paper style={{ padding: 40, paddingTop: 64, maxWidth: 400, position: 'relative' }}>
        <div style={{ position: 'absolute', top: 12, right: 12 }}>
          <LanguageSelect withFlag withText />
        </div>
        <Text style={{ textAlign: 'center' }} variant={'h4'}>{LangKey.AuthHeader}</Text>
        <Text style={{ textAlign: 'center', margin: '16px 0' }}>{LangKey.AuthTitle}</Text>
        {
          !appModel.isTapio && (
            <FormTextField
              field={'client_id'}
              model={form}
              label={LangKey.AuthClient.t()}
              fullWidth
              disabled={loading}
            />
          )
        }
        <FormTextField
          field={'username'}
          model={form}
          fullWidth
          label={LangKey.AuthLogin.t()}
          disabled={loading}
        />
        <FormTextField
          field={'password'}
          model={form}
          label={LangKey.AuthPassword.t()}
          fullWidth
          type={'password'}
          disabled={loading}
        />
        <Text
          style={{ opacity: error ? 1 : 0 }}
          color={'error'}
        >
          {error || 'error'}
        </Text>
        <Button
          loading={loading}
          size={'large'}
          style={{ marginTop: '24px' }}
          onClick={onLoginClick}
          fullWidth
          variant={'contained'}
          color={'primary'}
        >
          {LangKey.AuthLoginButton}
        </Button>
        {process.env.NODE_ENV === 'development' &&
          <HBox gap={0} align={'center'} style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <TestButton
              name={'dorohi'}
              credentials={{ client_id: 'dorohi', username: 'dorohi@gmail.com', password: 'qqqqqq1Q' }}
            />
            <TestButton
              name={'thu/tbua'}
              credentials={{ client_id: 'thuyphuong', username: 'tbua', password: 'tlmy06' }}
            />
            <TestButton
              name={'demo/admin'}
              credentials={{ client_id: 'demo', username: 'admin', password: 'test' }}
            />
            <TestButton
              name={'CPX/admin'}
              credentials={{ client_id: 'cotopaxi', username: 'admin', password: 'sdBsg7Gc' }}
            />
            <TestButton
              name={'CPX/atte'}
              credentials={{ client_id: 'cotopaxi', username: 'atte_simosol_assignee', password: 'ib94jnkofs4' }}
            />
          </HBox>
        }
      </Paper>
    </CenterContainer>
  );
};

export default Auth;
