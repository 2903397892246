import * as React from 'react';
import * as ReactDOM from 'react-dom';
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import 'typeface-roboto';
import './styles/index.scss';

import createMuiTheme from '@material-ui/core/styles/createTheme';
import { lang } from './Lang';
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline';
import AppContainer from './views/app/AppContainer';
import UpdateDialog from './views/app/UpdateDialog';
import { register } from './serviceWorker';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { default as MomentUtils } from '@date-io/moment';
import { MuiThemeProvider } from '@material-ui/core';

// import DevTools from 'mobx-react-devtools';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    appDrawer: {
      width: number;
    };
    appBar: {
      toolBarHeight: number;
      tabsHeight: number;
    };
  }
  // noinspection JSUnusedGlobalSymbols
  interface ThemeOptions {
    appDrawer: {
      width: number;
    };
    appBar: {
      toolBarHeight: number;
      tabsHeight: number;
    };
  }
}

const root = document.getElementById('root') as HTMLElement;
export const appPalette = {
  50: '#f2f6e6',
  100: '#dee9c1',
  200: '#c8da98',
  300: '#b1cb6f',
  400: '#a1bf50',
  500: '#90b431',
  600: '#88ad2c',
  700: '#7da425',
  800: '#739c1f',
  900: '#1b4243',
  A100: '#e6ffc0',
  A200: '#d3ff8d',
  A400: '#bfff5a',
  A700: '#b5ff41',
};

export const isCordova = navigator.userAgent.match(/Cordova/i);
let initDevice = (onComplete: () => void) => { onComplete(); };
if (isCordova) {
  initDevice = (onComplete: () => void) => {
    document.addEventListener(
      'deviceready',
      () => {
        onComplete();
      },
      false);
  };
} else {
  register();
}

const initDom = () => {
  const theme = createMuiTheme({
    palette: {
      primary: appPalette,
      secondary: amber,
      error: red,
      text: {
        primary: '#000000',
      },
      contrastThreshold: 1.8,
    },
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
        },
      },
    },
    appDrawer: {
      width: 240,
    },
    appBar: {
      toolBarHeight: 64,
      tabsHeight: 48,
    },
    typography: {
    },
  });

  ReactDOM.render(
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <UpdateDialog>
            <AppContainer/>
          </UpdateDialog>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
      {/*<DevTools />*/}
    </>,
    root as HTMLElement,
  );
};

initDevice(() => lang.init(initDom));
