import { LangKey } from '../../LangKey';

export type LayerName = 'estatesBo' | 'OSD' | 'kemeraApp' | 'kemeraCom' | 'specialFeatures' | 'keilausalueet' | 'mki';

export type VectorLayer = {
  name: LayerName,
  label: string,
  tileURL: string,
  checked: boolean,
};

const vectorLayers: VectorLayer[] = [
  {
    name: 'estatesBo',
    label: LangKey.MapVectorLayersBorder,
    tileURL: 'https://forestkit.etapio.fi/static/mtk/estate_borders.json',
    checked: true,
  },
  {
    name: 'OSD',
    label: LangKey.MapVectorLayersOsd,
    tileURL: 'https://forestkit.etapio.fi/static/mtk/osdstyles.json',
    checked: false,
  },
  {
    name: 'kemeraApp',
    label: LangKey.MapVectorLayersCameraApp,
    tileURL: 'https://forestkit.etapio.fi/static/mtk/kemera_app_styles.json',
    checked: false,
  },
  {
    name: 'kemeraCom',
    label: LangKey.MapVectorLayersCameraCom,
    tileURL: 'https://forestkit.etapio.fi/static/mtk/kemera_com_styles.json',
    checked: false,
  },
  {
    name: 'specialFeatures',
    label: LangKey.MapVectorLayersSpecFeature,
    tileURL: 'https://forestkit.etapio.fi/static/mtk/specialfeatures_styles.json',
    checked: false,
  },
  {
    name: 'keilausalueet',
    label: LangKey.MapVectorLayersBowling,
    tileURL: 'https://forestkit.etapio.fi/static/mtk/keilausalueet_style.json',
    checked: false,
  },
  {
    name: 'mki',
    label: LangKey.MapVectorLayersMki,
    tileURL: 'https://forestkit.etapio.fi/static/mtk/mki_styles.json',
    checked: false,
  },
];

export default vectorLayers;
