import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import AppToolbar from '../app/appBar/AppToolbar';
import { LangKey } from '../../LangKey';
import LanguageSelector from './LanguageSelector';
import AgeKindSelector from './AgeKindSelector';
import { VBox } from '../../uicomponents/Boxes';
import MapStandCheckbox from './MapStandCheckbox';
import { appModel } from '../../models/App';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    margin: theme.spacing(3),
  },
}));

const Settings = () => {
  const classes = useStyles();
  return (
    <>
      <AppToolbar title={LangKey.SettingsTitle} button={{ type: 'menu' }}/>
      <VBox className={classes.root}>
        <LanguageSelector />
        <AgeKindSelector />
        {appModel.isTapio && <MapStandCheckbox/>}
      </VBox>
    </>
  );
};

export default Settings;
