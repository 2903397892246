import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { default as CloseIcon } from '@material-ui/icons/Close';
import { action, observable } from 'mobx';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import SnackbarContent from '@material-ui/core/SnackbarContent/SnackbarContent';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { default as ErrorIcon } from '@material-ui/icons/Error';
import { default as withReactions, WithReactionsProps } from '../../withReactions';
import { appModel } from '../../models/App';
import { LangKey } from '../../LangKey';

type ResultSnackBarProps = {} & WithStyles<typeof styles> & WithReactionsProps;

const styles = (theme: Theme) => createStyles({
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

export default class ResultSnackBar extends React.Component<ResultSnackBarProps> {
  static model = observable({
    open: false,
    text: '',
    error: false,
  });

  @action
  static show(text: string, error: boolean = false) {
    const model = ResultSnackBar.model;
    model.open = true;
    model.text = text.t();
    model.error = error;
  }

  static hide() {
    ResultSnackBar.model.open = false;
  }

  componentDidMount() {
    this.props.reactions.reaction(
      () => appModel.criticalError,
      () => ResultSnackBar.show(appModel.criticalError || LangKey.CommonUnknownCriticalError, true),
    );
  }

  render() {
    const model = ResultSnackBar.model;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}

        open={model.open}
        autoHideDuration={10000}
        onClose={ResultSnackBar.hide}
      >
        <SnackbarContent
          className={model.error ? this.props.classes.error : undefined}
          style={{ flexWrap: 'nowrap' }}
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {model.error && <ErrorIcon style={{ marginRight: 16 }}/>}
              {ResultSnackBar.model.text}
            </span>}
          action={
            <IconButton
              key="close"
              color="inherit"
              onClick={ResultSnackBar.hide}
            >
              <CloseIcon/>
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

export const ResultSnackBarStyled = withStyles(styles)(withReactions(ResultSnackBar));
