import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormLabel from '@material-ui/core/FormLabel/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import { allLanguages, lang, LangType } from '../../Lang';
import { LangKey } from '../../LangKey';

const styles = (theme: Theme) => createStyles({
  group: {
    margin: theme.spacing(3, 0),
  },
});

type LanguageSelectorProps = {} & WithStyles<typeof styles>;

class LanguageSelector extends React.Component<LanguageSelectorProps> {
  private _onRadioChange = (event: React.ChangeEvent<{}>, value: string) => {
    lang.change(value as LangType);
  }

  render() {
    const { classes } = this.props;
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{LangKey.SettingsLanguage.t()}</FormLabel>
        <RadioGroup
          className={classes.group}
          value={lang.locale}
          onChange={this._onRadioChange}
        >
          { allLanguages.map(item => (
            <FormControlLabel
              key={item.lang}
              value={item.lang}
              control={<Radio color={'default'} />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default withStyles(styles)(LanguageSelector);
