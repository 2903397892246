import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { appModel } from '../../models/App';
import { FreeGeometry } from '../../models/FreeGeometries';
import AppToolbar from '../app/appBar/AppToolbar';
import { LangKey } from '../../LangKey';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import DataPreloader from '../../uicomponents/DataPreloader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Text from '../../uicomponents/Text';
import { taskColorFromStatus } from '../tasks/Tasks';
import { observer } from 'mobx-react-lite';
import { joinSecondary } from '../../models/Utils';

const useStyles = makeStyles((theme: Theme) => createStyles({
  spacer: {
    flexGrow: 1,
  },
}));

const FreeGeometries = observer(() => {
  const styles = useStyles();
  const fgs = appModel.freeGeometries.freeGeometries;
  const showOnlyAssignedToMe = appModel.onlyAssigned;

  const filteredFG = showOnlyAssignedToMe && appModel.isTapio
    ? fgs.filter(fg => fg.assignedToMe)
    : fgs;

  const onFGClick = (fg: FreeGeometry) => () => {
    appModel.browser.page = { p: 'freeGeometry', p1: `${fg.id}` };
  };

  return (
    <>
      <AppToolbar title={LangKey.FreeGeometryTitle} button={{ type: 'menu' }}>
        <div className={styles.spacer} />
        {appModel.isTapio && (
          <FormControlLabel
            control={ (
              <Checkbox
                checked={showOnlyAssignedToMe}
                onChange={() => appModel.onlyAssigned = !appModel.onlyAssigned}
                color="primary"
              />
            )}
            label={LangKey.TaskAssigned.t()}
          />
        )}
      </AppToolbar>
      <DataPreloader>
        {filteredFG.map(fg => (
          <ListItem button key={fg.id} onClick={onFGClick(fg)}>
            <ListItemIcon>
              <svg height="24" width="24">
                <circle
                  cx="12"
                  cy="12"
                  r="12"
                  stroke="none"
                  fill={taskColorFromStatus(fg.operationStatus)}
                />
              </svg>
            </ListItemIcon>
            <ListItemText
              primary={fg.name}
              secondary={joinSecondary([fg.operationAssigneeName, fg.operationDateStart, fg.executionDetails], ' / ')}
            />
            <Text>{fg.plannedYear}</Text>
          </ListItem>
        ))}
      </DataPreloader>
    </>
  );
});

export default FreeGeometries;
