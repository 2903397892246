import * as React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import createStyles from '@material-ui/core/styles/createStyles';
import { appModel } from '../../models/App';
import AppToolbar from '../app/appBar/AppToolbar';
import { observer } from 'mobx-react';
import DataPreloader from '../../uicomponents/DataPreloader';
import DynamicElementProps from './DynamicElementProps';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '../../uicomponents/ListItemText';
import Text from '../../uicomponents/Text';
import MapOpenButton from '../map/MapOpenButton';
import { action } from 'mobx';
import { DataNode, propsDisplayFiltered } from '../../models/DataNodeUtils';

const styles = (theme: Theme) => createStyles({
  root: {},
  propsTableInList: {
    marginBottom: theme.spacing(3),
  },
});

type Props = {} & WithStyles<typeof styles>;

@observer
class DynamicElement extends React.Component<Props> {
  private _onBackClick = () => {
    appModel.stands.previousStandPage();
  }

  private _onElementClick = (element: DataNode) => () => {
    appModel.stands.onElementClick(element);
  }

  private _getTitle = (dynamicElement: DataNode): string => {
    let title: string = dynamicElement.listPrimaryPropDisplayValue || '';
    let parentTitle: string = '';
    if (dynamicElement.parent) {
      parentTitle = this._getTitle(dynamicElement.parent);
    }
    if (parentTitle) title = parentTitle + ' — ' + title;
    return title;
  }

  @action
  private _onMapOpen = () => {
    const stand = appModel.stands.currentStand;
    if (!stand) return;
    // appModel.stands.back();
    const pg = appModel.browser.page;
    if (pg.p !== 'map') {
      appModel.map.showMap(stand.project.id, stand.id);
    }
  }

  render() {
    const { classes } = this.props;
    const pageData = appModel.stands.currentPageData;
    const stand = appModel.stands.currentStand;
    const tabs = appModel.stands.tabs;
    let title = '';
    if (pageData) {
      title = this._getTitle(pageData.element);
    }
    return (
      <>
        <AppToolbar
          title={title}
          button={{ type: 'back', onClick: this._onBackClick }}
          tabs={tabs.data.length > 0 ? tabs : undefined}
        />
        <DataPreloader>
          {pageData &&
          <>
            {pageData.type === 'info' &&
              <DynamicElementProps model={propsDisplayFiltered(pageData.element.props)} />
            }
            {pageData.type === 'prop' && pageData.prop.nodes.map((element, index) =>
              element.listPrimaryPropDisplayValue
                ? <ListItem button key={index} onClick={this._onElementClick(element)}>
                    <ListItemText
                      primary={element.listPrimaryPropDisplayValue}
                    />
                    {element.listSecondaryPropDisplayValue &&
                      <Text>{element.listSecondaryPropDisplayValue}</Text>
                    }
                  </ListItem>
                : (
                  <DynamicElementProps
                    className={classes.propsTableInList}
                    key={index}
                    model={propsDisplayFiltered(element.props)}
                    headerLabel={'#' + (index + 1).toString()}
                  />
                ),
            )}
            {stand?.geoJSONToMap && <MapOpenButton onClick={this._onMapOpen} />}
          </>
          }
        </DataPreloader>
      </>
    );
  }
}

export default withStyles(styles)(DynamicElement);
