import { computed, makeObservable, observable } from 'mobx';
import { StandPage } from './models/Stands';

export enum PageTask {
  info = 'info',
  notes = 'notes',
}

export enum PageProjects {
  list = 'list',
  map = 'map',
}

export const isTaskNotesPage = (page: Page): page is PageTaskNotes => {
  return (page.p === 'task' && page.p2 === PageTask.notes);
};
export const isTaskNotesEditPage = (page: PageTaskNotes): page is PageTaskNotesEdit => {
  return page.p3 === 'edit';
};

export type PageTaskNotes =
  { p: 'task' , p1: string, p2: PageTask.notes, p3: 'view' } |
  { p: 'task' , p1: string, p2: PageTask.notes, p3: 'new' } |
  PageTaskNotesEdit;
export type PageTaskNotesEdit = { p: 'task' , p1: string, p2: PageTask.notes, p3: 'edit', p4: string };

export type Page =
    { p: ''}
  | { p: 'tasks' }
  | { p: 'settings' }
  | { p: 'projects', p1?: PageProjects }
  | { p: 'project', p1: string }
  | { p: 'task' , p1: string, p2: PageTask.info }
  | { p: 'freeGeometries' }
  | { p: 'freeGeometry', p1: string }
  | PageTaskNotes
  | StandPage
  | { p: 'map', p1: 'project' | 'freeGeometry', p2: string, p3?: string }
  ;

export default class Browser {

  @observable.shallow
  private _page: Page = { p: '' };

  private _settingHash: boolean = false;

  constructor() {
    makeObservable(this);
    window.addEventListener('hashchange', this._setPageFromHash);
    this._setPageFromHash();
  }

  set page(value: Page) {
    if (this._page === value) return;
    this._page = value;
    let hash: string = '';
    const len = Object.keys(this._page).length;
    for (let i = 0; i < len; i += 1) {
      if (i === 0) {
        hash = this._page['p'];
      } else {
        hash += '/' + this._page['p' + i];
      }
    }
    this._settingHash = true;
    window.scrollTo(window.scrollX, 0);
    window.location.hash = hash;
  }

  @computed
  get page(): Page {
    return this._page;
  }

  private _setPageFromHash = (e?: HashChangeEvent) => {
    if (this._settingHash) {
      this._settingHash = false;
      return;
    }
    this._settingHash = false;

    if (window.location) {
      if (window.location.hash) {
        const hash = decodeURIComponent(window.location.hash);
        const urlParts = hash.substr(1).split(/\//);
        const pg = {};
        for (let i = 0; i < urlParts.length; i += 1) {
          pg['p' + ((i > 0) ? i : '')] = urlParts[i];
        }
        this._page = pg as Page;
      } else {
        this._page = { p: '' };
      }
      window.scrollTo(window.scrollX, 0);
    }
  }

  back = () => {
    window.history.back();
  }
}
